@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: 'Roboto', sans-serif;
    font-size: large;
}

#App {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 1rem;
}

#App #Root {
    width: 90%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

#App #Root #Topbar {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

#App #Root #Topbar .topbar-item {
    color: rgb(0, 0, 238);
    text-decoration: none;
}

#App #Root #Topbar .fb {
    width: auto;
    height: auto;
}

#App #Root #Topbar .topbar-item svg {
    width: 30px;
    height: 30px;
}

#App #Root #Topbar img {
    width: 100px;
}

#App #Root #Navbar {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 2rem;
    margin: 1rem;
}

#App #Root #Navbar .nav-item {
    text-decoration: none;
    color: #6c6c6c;
    text-align: center;
}

#App #Root #Navbar .nav-item:hover {
    color: rgb(78, 0, 250);
}

#App #Home {
    width: 90%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

#App #Home .bg-img {
    width: 100%;
    height: 60vh;
    background-image: url('./imgs/bg-img.webp');
    background-size: cover;
    background-position: center;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    margin: 1rem 0;
}

#App #Home .bg-img .welcoming {
    width: 40%;
    height: 100%;
    background-color: rgba(243, 242, 255, 0.8);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

#App #Home .bg-img .welcoming h1 {
    font-weight: 500;
    font-size: 2rem;
    text-align: center;
    color: rgb(78, 0, 250);
}

#App #Home .bg-img .welcoming p {
    font-weight: 300;
    font-size: 2rem;
    color: #6c6c6c;
    text-align: center;
    margin-top: 1rem;
}

#App #Home .home-section-two {
    width: 90%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 1rem 0;
}

#App #Home .home-section-two h2 {
    font-size: 2rem;
    font-weight: 400;
    text-align: center;
}

#App #Home .home-section-two p {
    font-size: 1.5rem;
    font-weight: 500;
    margin-top: 1rem;
    text-align: center;
}

.btn {
    padding: 8px 20px;
    background-color: rgb(78, 0, 250);
    border-radius: .8rem;
    margin: 1rem 0;
}

.btn * {
    color: white;
    font-weight: 600;
    font-size: 18px;
    text-decoration: none;
    text-align: center;
}

#App #Home .home-section-three {
    width: 100%;
    height: 50vh;
    background-image: url('./imgs/bg-img-2.webp');
    background-size: cover;
    background-position: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 1rem 0;
}

#App #Home .home-section-three h2 {
    font-size: 2rem;
    font-weight: 400;
    text-align: center;
    color: white;
}

#Footer {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 1rem 0 0 0;
}

#Footer svg {
    width: 30px;
    height: 30px;
}

#Footer .copyright {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-top: 1rem;
}

#Card {
    width: 20rem;
    background-color: #F3F2FF;
    border-radius: .9rem;
    margin-top: .5rem;
}

#Card .card-img {
    width: 100%;
    height: 12rem;
    background-size: cover;
    background-position: center;
    border-radius: .9rem;
}

#Card .card-img img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: .9rem;
}

#Card h2 {
    text-align: center;
    font-size: 2rem;
    font-weight: 400;
    margin: 1rem 0;
}

#Card p {
    text-align: center;
    font-weight: 400;
    font-size: 20px;
    padding: 1rem;
}

#App #About {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.row {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: flex-start;
    margin: 1rem 1rem;
    flex-wrap: wrap;
}

.page-header {
    text-align: center;
    color: rgb(78, 0, 250);
    margin: 1rem 0;
}

#Employment {
    width: 100%;
}

#Employment .steps {
    width: 100%;
}

#Employment .steps form {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

#Employment .steps form h2 {
    text-align: center;
}

#Employment .steps form h2 {
    font-weight: 300;
    margin: .2rem 0;
}

#Employment .steps form .upload {
    display: flex;
    justify-content: center;
    align-items: center;
}

#Employment .steps form .upload .btn {
    color: white;
    font-weight: 500;
}

#Employment .steps .upload .btn:hover {
    cursor: pointer;
}

#Contact {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

#Contact form {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 90%;
}

#Contact form *:not(button) {
    width: 100%;
    margin: .5rem;
}

#Contact form input {
    border: none;
    border-bottom: 1px solid #6c6c6c;
}

#Contact form button {
    color: white;
    font-weight: 500;
    border: none;
    cursor: pointer;
}

#Sidebar {
    display: none;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    background-color: rgba(255, 255, 255, 0.125);
    padding: 2rem 1rem;
    width: 50%;
    height: 100vh;
    z-index: 999;
    position: fixed;
    top: 0;
    right: 0;
    box-shadow: -10px 0 10px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(100px);
}

#Sidebar *:not(.fbicon,.close) {
    width: 100%;
    padding: 1rem .5rem;
    text-decoration: none;
    color: #6c6c6c;
    margin-left: .3rem;
}

#Sidebar *:not(.fbicon,.close):hover {
    background-color: #6c6c6c0e;
}

.close {
    color: black;
    width: 30px;
    height: 30px;
    cursor: pointer;
    margin: 1rem 0;
}

@media(max-width: 700px) {
    .hideOnMobile {
        display: none;
    }
    .hideOnPc {
        display: inline;
    }
    #App #Home .bg-img {
        flex-direction: column;
        justify-content: flex-end;
        align-items: center;
    }
    #App #Home .bg-img .welcoming {
        width: 100%;
    }
    #Employment .steps form .upload {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin: 1rem 0;
    }
    #Footer .copyright {
        flex-direction: column;
    }
    #Footer .copyright h3 {
        text-align: center;
        margin: .3rem 0;
    }
}

@media(min-width: 700px) {
    .hideOnMobile {
        display: flex;
    }
    .hideOnPc {
        display: none;
    }
}

.fbicon svg {
    margin: 1rem 0;
    width: 40px;
    height: 40px;
    color: rgb(0, 0, 238);
}